@tailwind base;
@tailwind components;

@font-face {
  font-family: 'Nanami-Book';
  src: url("./fonts/Nanami-Book.otf");
}
@font-face {
  font-family: 'Nanami-Light';
  src: url("./fonts/Nanami-Light.otf");
}
@font-face {
  font-family: 'Nanami-Thin';
  src: url("./fonts/Nanami-thin.otf");
}
@font-face {
  font-family: 'Nanami-ExtraLight';
  src: url("./fonts/Nanami-ExtraLight.otf");
}
@font-face {
  font-family: 'Urbanist-ExtraLight';
  src: url("./fonts/urbanist/Urbanist-Light.ttf");
}
@font-face {
  font-family: 'Urbanist-Light';
  src: url("./fonts/urbanist/Urbanist-Regular.ttf");
}
@font-face {
  font-family: 'Urbanist-SemiBold';
  src: url("./fonts/urbanist/Urbanist-SemiBold.ttf");
}

@tailwind utilities;

body {
  background-color: #EEEEEE;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
